@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRODISPLAYREGULAR.OTF');
  font-weight: normal;
  font-style: normal;
}

@layer base {
  html {
    font-family: 'SF Pro Display';
  }
}

@layer components {
  .markdown-content > h1 {
    @apply text-2xl font-bold text-black mb-9;
  }

  .markdown-content > h2 {
    @apply text-xl font-bold text-black mb-3;
  }

  .markdown-content > p {
    @apply text-base text-gray-500 mb-9 text-justify;
  }

  .markdown-content > ul,
  .markdown-content > ol {
    @apply text-base text-gray-500 mb-9 ml-8;
  }

  .markdown-content > ol {
    @apply list-decimal;
  }

  .markdown-content > ul {
    @apply list-disc;
  }
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

strong {
  color: black;
}
